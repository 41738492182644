// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void
    onUpdate?: (registration: ServiceWorkerRegistration) => void
}

class ServiceWorker {
    // This optional code is used to register a service worker.
    register(config?: Config) {
        if ("serviceWorker" in navigator) {
            // The URL constructor is available in all browsers that support SW.
            const publicUrl = new URL("/", window.location.href)

            if (publicUrl.origin !== window.location.origin) {
                // Our service worker won't work if PUBLIC_URL is on a different origin
                // from what our page is served on. This might happen if a CDN is used to
                // serve assets; see https://github.com/facebook/create-react-app/issues/2374
                return
            }

            window.addEventListener("load", () => {
                const swUrl = `/service-worker.js`
                const runServiceWorker = () => {
                    this.checkSwFile(swUrl, (exist: boolean) => {
                        if (exist) {
                            this.checkValidServiceWorker(swUrl, config)
                        }
                    })
                }
                runServiceWorker()
                setInterval(() => {
                    runServiceWorker()
                }, 10000)
            })
        }
    }

    registerValidSW(swUrl: string, config?: Config) {
        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
                registration.update()
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing
                    if (installingWorker == null) {
                        return
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === "installed") {
                            if (navigator.serviceWorker.controller) {
                                // Execute callback
                                if (config?.onUpdate) {
                                    config.onUpdate(registration)
                                }
                            } else {
                                // Execute callback
                                if (config?.onSuccess) {
                                    config.onSuccess(registration)
                                }
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("Error during service worker registration:", error)
            })
    }

    checkValidServiceWorker(swUrl: string, config?: Config) {
        // Check if the service worker can be found. If it can't reload the page.
        fetch(swUrl, {
            headers: { "Service-Worker": "script" },
        })
            .then((response) => {
                // Ensure service worker exists, and that we really are getting a JS file.
                const contentType = response.headers.get("content-type")
                if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
                    // No service worker found. Probably a different app. Reload the page.
                    navigator.serviceWorker.ready.then((registration) => {
                        registration.unregister().then(() => {
                            window.location.reload()
                        })
                    })
                } else {
                    // Service worker found. Proceed as normal.
                    this.registerValidSW(swUrl, config)
                }
            })
            .catch(() => {
                console.log("No internet connection found. App is running in offline mode.")
            })
    }

    checkSwFile = (url: string, callback: (data: boolean) => void) => {
        const xhr = new XMLHttpRequest()
        xhr.open("HEAD", url, true)
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // Le fichier existe
                    callback(true)
                } else {
                    // Le fichier n'existe pas ou il y a une erreur
                    callback(false)
                }
            }
        }
        xhr.send()
    }

    unregister() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready
                .then((registration) => {
                    registration.unregister()
                })
                .catch((error) => {
                    console.error(error.message)
                })
        }
    }
}

export default new ServiceWorker()
